class ElasticService {
  url = process.env.VUE_APP_ELASTIC_PROXY_URL;
  apiKey = process.env.VUE_APP_ELASTIC_PROXY_API_KEY;
  indexPrefix = process.env.VUE_APP_ELASTIC_INDEX_PREFIX;
  perPage = 10;

  /**
   *
   * @param {String} type - Тип
   * @param {Number} page - Номер страницы
   * @param {String} query - Строка поиска
   * @param {String} date - Дата "ГГГГ-ММ-ДД"
   * @param {Array} must
   * @returns {Promise<any>}
   */
  get(type, { page = 1, query = "", date = null }, must = []) {
    const url = `/${this.indexPrefix}*/_search`;

    let _body = {
      body: {
        from: (page - 1) * this.perPage,
        size: this.perPage,
        query: {
          bool: {
            must: [
              {
                term: {
                  is_test: 0,
                },
              },
              {
                term: {
                  type: type,
                },
              },
              {
                range: {
                  log_dt: {
                    gte: date,
                    lte: date,
                  },
                },
              },
              ...must
            ],
          },
        },
        sort: [
          {
            log_dt: "desc",
          },
        ],
      },
      url,
    };

    if (query) {
      _body.body.query.bool.must.push({
        query_string: {
          query: `${query}`
        },
      });
    }

    return fetch(this.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      },
      body: JSON.stringify(_body),
    }).then((response) => response.json());
  }
}

export default new ElasticService();
